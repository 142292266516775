define("discourse/plugins/discourse-circles/discourse/components/circles/add-circles-category-body-class", ["exports", "@glimmer/component", "discourse/helpers/body-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _bodyClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddCirclesCategoryBodyClass extends _component.default {
    static shouldRender(args) {
      return args?.category?.is_circle ||
      // above-category-heading
      args?.model?.category?.is_circle // topic-above-post-stream
      ;
    }
    // adds a class to the body
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      {{bodyClass "circles-category--is-circle"}}
    */
    {
      "id": "FdskKRb/",
      "block": "[[[1,[28,[32,0],[\"circles-category--is-circle\"],null]]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/add-circles-category-body-class.js",
      "scope": () => [_bodyClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AddCirclesCategoryBodyClass;
});